import { render, staticRenderFns } from "./BuyVip.vue?vue&type=template&id=60304954&scoped=true&"
import script from "./BuyVip.vue?vue&type=script&lang=js&"
export * from "./BuyVip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60304954",
  null
  
)

export default component.exports