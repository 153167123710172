<template>
    <div>
        <div class="card_mine">
            <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/card_back.png" class="home_swipe_back" alt="">
            <div style="padding-top: 50px;">
                <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/card.png" class="swipe_back" alt="">
                <div class="swipe_card_vip" style="padding-top: 50px;">
                    <div class="card_detail_left">
                        <div>
                            <span>黑卡会员</span>
                            <p>尊享专属特权，包月服务更优惠</p>
                        </div>
                    </div>
                    <div class="card_detail_right">
                        <img src="../../assets/img/king.png" alt="">
                        <p style="font-size: 15px;text-align: center;color: #fff;margin-top: 25%;">¥ {{vip_data[0].price}}元/月</p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <van-divider style="border:none;color: #000000;font-size: 16px;margin-bottom: 0;">
                <img src="../../assets/img/clip.png" style="height: 14px;margin-right: 10px;" alt="">
                黑卡会员尊享4项权益
                <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 10px;" alt="">
            </van-divider>
            <van-grid :column-num="2" :border="false" class="vip_rights">
                <van-grid-item class="rights">
                    <van-image :src="rightsIcon[0]" width="40" />
                    <span class="card_span rights_button">1V1咨询</span>
                    <span>专属顾问</span>
                </van-grid-item>
                <van-grid-item class="rights">
                    <van-image :src="rightsIcon[1]" width="40" />
                    <span class="card_span rights_button">增加访客收藏</span>
                    <span>搜索排名</span>
                </van-grid-item>
<!--                <van-grid-item class="rights">-->
<!--                    <van-image :src="rightsIcon[2]" width="40" />-->
<!--                    <span class="card_span rights_button">删差评</span>-->
<!--                    <span>辅助售后</span>-->
<!--                </van-grid-item>-->
<!--                <van-grid-item class="rights">-->
<!--                    <van-image :src="rightsIcon[3]" width="40" />-->
<!--                    <span class="card_span rights_button">2次/月</span>-->
<!--                    <span>平面设计</span>-->
<!--                </van-grid-item>-->
                <van-grid-item class="rights">
                    <van-image :src="rightsIcon[4]" width="40" />
                    <span class="card_span rights_button">提升店铺星级</span>
                    <span>星级评分</span>
                </van-grid-item>
                <van-grid-item class="rights">
                    <van-image :src="rightsIcon[5]" width="40" />
                    <span class="card_span rights_button">专业设计师</span>
                    <span>店铺装修</span>
                </van-grid-item>
            </van-grid>
<!--            <van-divider style="border:none;color: #000000;font-size: 16px;">-->
<!--                <img src="../../assets/img/clip.png" style="height: 14px;margin-right: 10px;" alt="">-->
<!--                黑卡会员尊享6项权益-->
<!--                <img src="../../assets/img/clip.png" style="height: 14px;margin-left: 10px;" alt="">-->
<!--            </van-divider>-->
<!--            <div class="vip_container">-->
<!--                <div v-for="item in vip_data" v-bind:key="item.vip_id" class="vip_tab" @click="outer_id = item.vip_id">-->
<!--                    <div :class="outer_id === item.vip_id ? 'vip_tab_select' : ''">-->
<!--                        <p>{{item.subtitle}}</p>-->
<!--                        <p>¥&nbsp;{{sale? item.price: item.price_before}}</p>-->
<!--                        <p :style="sale && item.price_before > item.price? '': 'visibility:hidden'">¥&nbsp;{{item.price_before}}</p>-->
<!--                        <p>{{item.description}}</p>-->
<!--                    </div>-->
<!--                    <van-tag v-if="item.price < item.price_before && sale" class="vip_tab_sale">限时特惠</van-tag>-->
<!--                </div>-->
<!--            </div>-->
            <p class="buy_vip_checkbox">
                <img src="../../assets/img/checkbox_back.png" alt="" @click="agree = !agree">
                <span><span @click="agree = !agree">我已阅读并同意</span><span @click="$router.push('/protocol')">《黑卡会员协议》</span></span>
                <img src="../../assets/img/checkbox_check.png" @click="agree = !agree" v-if="agree" alt="">
            </p>
        </div>
        <div style="margin: 0 10px 30px;">
            <van-button size="large" class="buy_vip_button" color="linear-gradient(to top, #e4c491, #e3ac5b)" @click="pay">立即购买</van-button>
        </div>
        <van-action-sheet v-model="phoneSheet">
            <p style="font-size:16px;text-align:center;font-weight:bold;">为保障您的权益，请验证手机号</p>
            <van-cell-group>
                  <van-field v-model="phoneNumber" label="手机号" placeholder="请输入手机号" :error-message="errMessage" />
                <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
                    <van-button slot="button" v-show="!counting" size="small" color="linear-gradient(to top, #e4c491, #e3ac5b)" type="primary" @click="sendMessage">发送验证码</van-button>
                    <van-button slot="button" v-show="counting" disabled size="small">
                        <van-count-down ref="countDown" :auto-start="false" :time="60 * 1000" format="sss"  @finish="finish"/>
                    </van-button>
                </van-field>
                <div style="margin:0 auto;">
                    <van-button size="small" style="display:block;width:40%;margin:25px auto;" color="linear-gradient(to top, #e4c491, #e3ac5b)" @click="pay">确定</van-button>
                </div>
            </van-cell-group>
        </van-action-sheet>        
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import wx from 'weixin-js-sdk';
    export default {
        name: "BuyVip",
        data(){
            return{
                sale: false, // 是否允许使用促销价格
                vip_id: 1, // 当前vip等级
                vip_data: [
                    {price:''}
                ], // 可购的vip
                rightsIcon:[
                    require('../../assets/img/rights_1_s.png'),
                    require('../../assets/img/rights_2_s.png'),
                    require('../../assets/img/rights_3_s.png'),
                    require('../../assets/img/rights_4_s.png'),
                    require('../../assets/img/rights_5_s.png'),
                    require('../../assets/img/rights_6_s.png'),
                ],

                agree: false, //checkbox
                outer_id: 0, // 会员id

                require_phone: true,
                phoneSheet: false, // 验证手机号弹框
                phoneNumber: '', // 手机号
                sms: '', // 短信验证码
                counting: false,
                errMessage:'',
                phoneNumberCheck: false,
                permitToPay: false,
            }
        },
        mounted(){
            this.loadVip();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        watch:{
            phoneNumber(newValue){
                if(this.phoneNumberCheck){
                    if(newValue.length === 11){
                        this.errMessage = '';
                    } else {
                        this.errMessage = '手机号格式错误';
                    }
                }
            }
        },
        methods:{
            loadVip(){
                this.post('/api/client/course/vip/load',{}).then(result=> {
                    if (result.code === 0) {
                        this.sale = result.data.sale; // 是否允许使用促销价格
                        this.vip_id = result.data.vip_id; // 当前vip等级
                        this.vip_data = result.data.vip_data; // 可购的vip
                        this.require_phone = result.data.require_phone; // 手机号验证
                    } else {
                        Toast(result.msg);
                    }
                });
            },
            // 发送验证短信
            sendMessage(){
                // 验证手机号是否已填写
                if(this.phoneNumber.length !== 11){
                    this.phoneNumberCheck = true;
                    this.errMessage = '手机号格式错误';
                    Toast('请填写正确的手机号');
                    return;
                }
                if( this.errMessage ){
                    Toast('请填写正确的手机号');
                    return;
                }
                // 验证通过，发送短信
                this.post('/api/client/common/message/send',{phone:this.phoneNumber}).then(result=>{
                    if(result.code === 0){
                        Toast.success(result.msg);
                        this.permitToPay = true;
                        this.counting = true;
                        this.$refs.countDown.start();
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            },
            // 倒计时结束
            finish(){
                this.counting = false;
                this.$refs.countDown.reset();
            },
            // 支付
            pay(){
                Dialog.alert({
                    title:'提示',
                    message:'暂时不允许购买',
                });
                return;
                let that = this;
                // 必选参数验证
                if(this.outer_id === 0){
                    this.outer_id = 2;  // 默认购买高级会员
                    // Toast('请选择黑卡会员类型');
                    // return;
                }
                if(!this.agree){
                    Toast('请阅读并同意黑卡会员协议');
                    return;
                }

                // 如果手机号未认证,打开验证手机的菜单
                if(this.require_phone){
                    if(this.phoneSheet){ // 如果已经打开了验证手机菜单，验证验证码是否已经输入完成
                        // 验证验证码是否正确输入
                        if(!this.permitToPay){
                            Toast('请先发送验证码');
                            return;
                        }
                        if(!(this.sms && this.sms.length === 6)){
                            Toast('请输入6位验证码');
                            return;
                        }
                    } else {
                        // 打开验证菜单
                        this.phoneSheet = true;
                        return;
                    }
                }


                // 验证通过,生成订单
                let params = {type: 1, outer_id: this.outer_id};
                if(this.require_phone){
                    params.code = this.sms;
                }
                this.post('/api/client/course/pay',params).then(result=>{
                    if(result.code === 0){
                        that.phoneSheet = false;
                        // 订单生成成功，支付
                        wx.chooseWXPay({
                            timestamp: result.data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            nonceStr: result.data.nonceStr, // 支付签名随机串，不长于 32 位
                            package: result.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: result.data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: result.data.paySign, // 支付签名
                            success: function (res) {
                                Toast.success('购买成功');
                                setTimeout(function(){
                                    // 支付成功后的回调函数
                                    that.$router.replace('/vipCard');
                                },2000)
                            }
                        });
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>